
import { computed, defineComponent, PropType } from 'vue'
import { Project } from '@/types/Project'

export default defineComponent({
  name: 'TopBlock',
  props: {
    project: {
      required: true,
      type: Object as PropType<Project>
    }
  },
  setup(props) {
    const links = computed(() => {
      const app_store = props.project.attributes.app_store
        ? {
            link: props.project.attributes.app_store,
            text: 'App Store'
          }
        : undefined
      const google_play = props.project.attributes.google_play
        ? {
            link: props.project.attributes.google_play,
            text: 'Google Play'
          }
        : undefined
      return [app_store, google_play].filter((item) => !!item)
    })

    return {
      links
    }
  }
})
