
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import TopBlock from '@/components/TopBlock.vue'
import { useRoute } from 'vue-router'
import Markdown from '@/components/Markdown.vue'
import ProjectsBlock from '@/components/ProjectsBlock.vue'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
import { SCROLL_FACTOR } from '@/assets/ts/smoothScroll'
import { WEBSITE_CONFIG } from '@/config'

export default defineComponent({
  name: 'Portfolio',
  components: {
    ProjectsBlock,
    Markdown,
    TopBlock
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const config = WEBSITE_CONFIG()

    const project = computed(() => store.state.project)
    const image = computed(
      () => `${config.url}${project.value?.attributes.cover.data.attributes.url}`
    )

    const scrollHandle = () => {
      const projects = document.getElementById('projects')
      if (projects) {
        const { offsetTop } = projects
        if (offsetTop < window.pageYOffset * SCROLL_FACTOR) {
          document.body.className = 'invert'
        } else {
          document.body.className = ''
        }
      }
    }

    const metaConfig = computed(() => {
      if (project.value) {
        const config = WEBSITE_CONFIG()
        const title = `${config.title}: ${project.value ? project.value.attributes.title : ''}`
        const description = project.value.attributes.description
        const image = `${config.url}${project.value.attributes.cover.data.attributes.url}`
        return {
          title,
          description,
          og: {
            title,
            description,
            type: 'website',
            image
          }
        }
      }
      return {}
    })

    useMeta(metaConfig)

    onBeforeMount(() => {
      const id = route.params.id
      store.dispatch('FETCH_PROJECT', id)
    })

    onBeforeRouteUpdate((to) => {
      window.scrollTo(0, 0)
      store.dispatch('FETCH_PROJECT', to.params.id)
    })

    onMounted(() => {
      window.scrollTo(0, 0)
      window.addEventListener('scroll', scrollHandle)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollHandle)
      document.body.className = ''
    })

    return {
      image,
      project
    }
  }
})
