
import { computed, defineComponent } from 'vue'
import ProjectCard from '@/components/ProjectCard.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { ProjectCard },
  setup() {
    const store = useStore()

    return {
      projects: computed(() => store.state.twoProjects)
    }
  }
})
