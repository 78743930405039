export const SCROLL_FACTOR = 1 // 2 // I don't know why

export const smoothScroll = (): void => {
  const app = document.querySelector('.app_container') as HTMLElement,
    speed = 0.07

  let offset = 0

  function smoothScroll() {
    offset += (window.pageYOffset - offset) * speed
    app.style.transform = `translateY(-${offset.toFixed(2)}px) translateZ(0)`

    requestAnimationFrame(smoothScroll)
  }

  smoothScroll()
}
