import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-144037d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBlock = _resolveComponent("TopBlock")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ProjectsBlock = _resolveComponent("ProjectsBlock")!

  return (_ctx.project)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createVNode(_component_TopBlock, { project: _ctx.project }, null, 8, ["project"]),
        _createElementVNode("img", {
          src: _ctx.image,
          alt: _ctx.project.attributes.cover.data.attributes.alternativeText,
          class: "project__cover"
        }, null, 8, _hoisted_2),
        _createVNode(_component_Markdown, {
          content: _ctx.project.attributes.content
        }, null, 8, ["content"]),
        _createVNode(_component_ProjectsBlock, { id: "projects" })
      ]))
    : _createCommentVNode("", true)
}