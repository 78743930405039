
import { defineComponent } from 'vue'
import marked from 'marked'

export default defineComponent({
  name: 'Markdown',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const html = marked(props.content).replaceAll('<a', '<a class="a"')

    return {
      html
    }
  }
})
